const imageFilenames = [
    "RC2_2302.jpg",
    "RC2_2303.jpg",
    "RC2_2305.jpg",
    "RC2_2306.jpg",
    "RC2_2307.jpg",
    "RC2_2310.jpg",
    "RC2_2311.jpg",
    "RC2_2312.jpg",
    "RC2_2315.jpg",
    "RC2_2316.jpg",
    "RC2_2317.jpg",
    "RC2_2318.jpg",
    "RC2_2319.jpg",
    "RC2_2320.jpg",
    "RC2_2322.jpg",
    "RC2_2323.jpg",
    "RC2_2330.jpg",
    "RC2_2332.jpg",
    "RC2_2336.jpg",
    "RC2_2339.jpg",
    "RC2_2345.jpg",
    "RC2_2346.jpg",
    "RC2_2349.jpg",
    "RC2_2351.jpg",
    "RC2_2352.jpg",
    "RC2_2353.jpg",
    "RC2_2354.jpg",
    "RC2_2355.jpg",
    "RC2_2356.jpg",
    "RC2_2357.jpg",
    "VCA_2706.jpg",
    "VCA_2707.jpg",
    "VCA_2709.jpg",
    "VCA_2710.jpg",
    "VCA_2714.jpg",
    "VCA_2715.jpg",
    "VCA_2718.jpg",
    "VCA_2720.jpg",
    "VCA_2727.jpg",
    "VCA_2728.jpg",
    "VCA_2730.jpg",
    "VCA_2732.jpg",
    "VCA_2733.jpg",
    "VCA_2734.jpg",
    "VCA_2735.jpg",
    "VCA_2740.jpg",
    "VCA_2749.jpg",
    "VCA_2750.jpg",
    "VCA_2751.jpg",
    "VCA_2757.jpg",
    "VCA_2762.jpg",
    "VCA_2763.jpg",
    "VCA_2765.jpg",
    "VCA_2767.jpg",
    "VCA_2768.jpg",
    "VCA_2776.jpg",
    "VCA_2781.jpg",
    "VCA_2785.jpg",
    "VCA_2788.jpg",
    "VCA_2789.jpg",
    "VCA_2807.jpg",
    "VCA_2808.jpg",
    "VCA_2809.jpg",
    "VCA_2812.jpg",
    "VCA_2815.jpg",
  ];
  
  export default imageFilenames;
  