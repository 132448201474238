import React from 'react';
import './homepage.css';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import Carousel from 'react-multi-carousel';
import imageFilenames from '../../imageList';
import 'react-multi-carousel/lib/styles.css';

// Responsive settings for the carousel
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

// Function to shuffle images (optional)
const shuffleArray = (array) => [...array].sort(() => Math.random() - 0.5);

export function Homepage(props) {
  // Shuffle the images for a dynamic carousel
  const shuffledImages = shuffleArray(imageFilenames);

  return (
    <>
      <Header />
      <div className="homepage_Container">
        <div className="homepage_Image"></div>
        <div className="homepage_Story">
          <h1>"Wanneer het koude je hart verwarmt"</h1>
          <div className="homepage_Story-text">
            Ik ben dat ene meisje in een wereld waar velen het ijsschaatsen omarmen, zelfs al ben ik als laatbloeier hierin gestart. Met onzekerheid betreed ik het ijs, mijn schaatsen beladen met de last van een late start. Maar met elke gracieuze slag bewijs ik dat leeftijd slechts een getal is, geen beperking. Mijn doorzettingsvermogen kent geen grenzen; vastberaden werk ik mezelf omhoog naar de top. Mijn verhaal herinnert ons aan de kracht van wilskracht en de schoonheid van laat ontluikende dromen.
          </div>
        </div>
      </div>
      <div className="homepage_Carousel">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={props.deviceType !== 'mobile'}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
          deviceType={props.deviceType}
          dotListClass="custom-dot-list-style"
        >
          {shuffledImages.map((filename, index) => (
            <img
              key={index}
              className="homepage_CarouselImage"
              src={`${process.env.PUBLIC_URL}/images/${filename}`}
              alt={`Slide ${index + 1}`}
            />
          ))}
        </Carousel>
      </div>
      <div className="homepage_CurrentState">
        <div className="homepage_CuttentState_content">
          <div className="homepage_CurrentState_title">
            Waar sta ik momenteel en wat zijn mijn plannen?
          </div>
          <div className="homepage_CurrentState_text">
            Ik zit momenteel in de categorie Intermediate Girls B en streef ernaar om volgend competitie seizoen te eindigen in Junior A Women. Met vastberadenheid werk ik aan mijn doel: de sprong maken van Intermediate Girls B naar Advanced Girls B en dan naar Junior A Women. Ik wil mijn talent en passie laten zien op het hoogste niveau van het kunstschaatsen.
          </div>
        </div>
        <div className="homepage_CurrentState_image"></div>
      </div>
      <Footer />
    </>
  );
}
